<!--
   User: Liu Yin
   Date: 2020/4/15
   Description:预览资讯
 -->
<template>
  <article style="position: relative" class="resource-detail">
    <return-key style="margin-left: 20px;margin-top: 20px" @close="handleBack"></return-key>
    <section v-if="!showDetail" class="content-parent">
      <section style="display: flex;margin-top: 15px">
        <div>
          <el-card shadow="never" :body-style="{ padding: '0px' }" class="item-block">
            <h3 class="company-custom" @click="handleToCompany(rowData.parkEnterpriseInfoDto.enterId)">
              <img :src="rowData.parkEnterpriseInfoDto.enterLogo" alt="公司LOGO">
              <span>{{ rowData.parkEnterpriseInfoDto.enterName }}</span>
            </h3>
            <div class="introduction">
              <p class="item-publish">联系人: {{ rowData.shareUser }}</p>
              <!-- <p class="item-publish">联系方式: {{ rowData.sharePhone }}</p> -->
              <p class="item-publish">公司官网:{{ rowData.parkEnterpriseInfoDto.enterWebsite }}</p>
              <p class="item-publish">公司地址：{{ rowData.parkEnterpriseInfoDto.enterAddress }}</p>
            </div>
          </el-card>
        </div>
        <div class="right-container">
          <el-card shadow="hover">
            <article style="display: flex">
              <section class="primary-left">
                <h2>{{ rowData.shareTitle }}</h2>
                <div class="primary-item">
                  <h4>开始时间:</h4>
                  <p>{{ rowData.starttime | formattingTime('YYYY-MM-DD HH:MM') }}</p>
                  <h4 style="margin-left: 20px;">结束时间:</h4>
                  <p>{{ rowData.endtime | formattingTime('YYYY-MM-DD HH:MM') }}</p>
                </div>
              </section>
            </article>
          </el-card>
          <el-card shadow="never" class="price">
            价格：<span style="color:blue">{{ rowData.sharePrice }}{{ rowData.shareUnit }} </span>
          </el-card>
          <el-card shadow="never" class="resources">
            <p>资源描述</p>
            <div class="rich-text" v-html="rowData.shareDetails"></div>
          </el-card>
          <el-card v-if="swiperImgs.length>0" shadow="never" :body-style="{ padding: '0px' }" style="padding-top:20px">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(item,index) in swiperImgs" :key="index">
                <div class="image-container">
                  <img class="swiper-slide-img" :src="item.pictureUrl" @click="openPicture(item.pictureUrl)">
                </div>
              </swiper-slide>
              <template v-slot:pagination>
                <div class="swiper-pagination"></div>
              </template>
            </swiper>
          </el-card>
        </div>
      </section>
    </section>
    <details-info v-if="showDetail" :id="enterId" @close="showDetail=false"></details-info>
  </article>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
import 'swiper/swiper-bundle.css'
import DetailsInfo from '../project/details'
export default {
  components: {
    swiper,
    swiperSlide,
    DetailsInfo
  },
  props: {
    rowId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: false,
          type: 'bullets',
          bulletClass: 'custom-bullet-class'
        }
      },
      swiperImgs: [],
      showDetail: false,
      enterId: null,
      rowData: {
        parkEnterpriseInfoDto: []
      }
    }
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    openPicture(url) {
      window.open(url)
    },
    // 获取章列表
    getDetailData() {
      this.toggleLoading(true)
      this.$get(this.urls.shareDetail, {}, [this.rowId]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          this.rowData = result.data
          this.swiperImgs = result.data.pictures
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
        this.$message.error('加载失败')
      })
    },
    openNewWindow(url) {
      this.toggleLoading(true)
      this.$get(this.urls.informationAddClick, {}, [this.rowData.inid]).then(result => {
        this.toggleLoading(false)
        if (result.code === 'SUCCESS') {
          window.open(url)
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    },
    handleBack() {
      this.$emit('close')
    },
    // 显示公司详情界面
    handleToCompany(item) {
      console.log(item)
      if (item != null) {
        this.enterId = item
        this.showDetail = true
      } else {
        console.log('未获取企业ID')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-parent {
  margin-left: px2vw(150);
  margin-right: px2vw(150);
  .item-publish {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: #3f3f3f;
    line-height: 32px;
  }
  .primary-item {
    display: flex;
    margin-top: px2vw(30);
    align-items: center;
    p {
      color: grey;
      margin-left: 5px;
      font-size: px2vw(18);
    }
    h4 {
      color: grey;
      font-weight: 500;
      font-size: px2vw(18);
    }
  }
  .item-block {
    // padding-top: 20px;
    width: 320px;
  }
  .image-container {
    height: 295px;
    width: 320px;
  }
  .swiper-slide-img {
    width: 100%;
    height: 260px;
    object-fit: contain;
    cursor: pointer;
  }
}
.back {
  position: fixed;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.back:hover {
  color: rgb(0, 198, 154);
}
.company-custom {
  line-height: 40px;
  background: rgb(250, 250, 250);
  color: rgb(123, 122, 121);
  img {
    margin: 0 auto;
    padding: 10px 0;
    width: 150px;
    height: 75px;
    border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 50%;
    display: block;
  }
  span {
    text-align: center;
    display: block;
    color: black;
  }
}
.right-container {
  width: calc(100% - 20%);
  margin-left: 20px;
  .el-card {
    width: 100%;
  }
  .price {
    margin: 4px 0;
    line-height: 40px;
  }
  .el-tabs {
    width: 100%;
  }
}
.introduction {
  padding-top: 20px;
  padding: 60px 8px 0 8px;
  p {
    margin: 12px 0;
  }
}
.resources {
  margin: 4px 0;
  ::v-deep .el-card__body {
    padding: 0;
  }
  p {
    background: #ebebeb;
    height: 60px;
    display: block;
    line-height: 60px;
    padding: 0 20px;
  }
  .rich-text {
    padding: 0 20px;
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 32px;
    margin: 18px 0;
  }
}
</style>

<style lang="scss">
.resource-detail {
  padding: 20px;
  background: rgb(244, 246, 250);
  .swiper-pagination {
    bottom: 10px;
    .custom-bullet-class {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 100%;
      height: 10px;
      width: 10px;
      border: 1px solid #008489;
      margin: 0 7px;
      display: inline-block;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: gray;
    }
  }
}
</style>

