<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:人才库简历
 -->
<template>
  <div>
    <article v-show="!showDetail" class="resume-list">
      <section class="search-expectCity">
        <div class="keyWords-expectCity">
          <el-input v-model="searchForm.keyWords" class="input-keyWords" placeholder="请输入关键字搜索" @keyup.enter.native="handleSearch" />
          <el-button type="primary" class="btn-search" @click="handleSearch">搜索</el-button>
        </div>
        <dl class="search-item-container">
          <dt class="search-item-container-title">资源类型: </dt>
          <dd class="search-item-container-list">
            <a v-for="item in calendarTypeOptions" :key="item.name" class="search-item-name" :class="{'search-item-name-focus':selectForm.shareType===item.name}" @click="handleSelectType(item)">
              {{ item.name }}
            </a>
          </dd>
        </dl>
        <float-label ref="floatLabel" class="float-label" :label-map="floatLabelMap" @handlerRemove="handleRemoveLabel"></float-label>
      </section>
      <resource-list :records="records" :total="total" :current-page="currentPage" @openResource="openResource" @changePage="changePage" />
    </article>
    <resource-detail v-if="showDetail" :row-id="rowId" @close="handleClose"></resource-detail>
  </div>
</template>

<script>
import floatLabel from '../../../../components/custom/floatLabel'
import resourceList from './components/resouceList'
import talentMixin from '../wisdom/mixins/talentMixin'
import listMixin from '../../../../utils/mixins/listMixin'
import resourceDetail from './resourceDetail'
export default {
  components: {
    resourceList,
    floatLabel,
    resourceDetail
  },
  mixins: [talentMixin, listMixin],
  data () {
    return {
      selectForm: {

      },
      calendarTypeOptions: [
        { key: '电脑', name: '电脑' },
        { key: '人力', name: '人力' },
        { key: '场地', name: '场地' },
        { key: '汽车', name: '汽车' },
        { key: '物资', name: '物资' },
        { key: '项目', name: '项目' }
      ],
      statusList: [
        { name: '未发布', key: '0' },
        { name: '待审核', key: 1 },
        { name: '已发布', key: 2 },
        { name: '未通过', key: 3 }],
      records: [],
      total: 0,
      // 浮动标签列表
      floatLabelMap: {}
    }
  },
  mounted () {
    // this.requestSearchForm()
    this.requestData()
  },
  methods: {
    // 请求列表数据
    requestData () {
      this.$get(this.urls.shareList, this.searchForm, [this.currentPage, 12]).then(result => {
        if (result.code === 'SUCCESS') {
          if (result.data.records === 0) {
            this.$message.error('沒有搜索到对应的数据')
          } else {
            this.records = result.data.records
            this.total = result.data.total
          }
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    },
    // 选中搜索条件,并将条件展示到浮动标签
    handlePitchOn (value, key) {
      this.$set(this.floatLabelMap, key, { value, key })
      this.currentPage = 1
      this.requestData()
    },
    // 选择类别
    handleSelectType (item) {
      this.searchForm.shareType = item.key
      this.selectForm.shareType = item.name
      this.handlePitchOn(item.name, 'shareType')
    },
    // 选择状态
    handleSelectState (item) {
      this.searchForm.shareState = item.key
      this.selectForm.shareState = item.name
      this.handlePitchOn(item.name, 'shareState')
    },
    // 移除选择的标签
    handleRemoveLabel (item) {
      this.searchForm[item.key] = null
      this.selectForm[item.key] = null
      this.currentPage = 1
      this.requestData()
    },
    changePage (num) {
      this.currentPage = num
      this.requestData()
    },
    // 关闭详情
    handleClose () {
      this.showDetail = false
      this.scrollToPosition()
    },
    // 打开资源
    openResource (row) {
      this.rowId = row.shareId
      this.showDetail = true
      this.setScrollPosition()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/list-search";
</style>
