<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:资源共享列表
 -->
<template>
  <article class="appraise">
    <el-empty v-if="records.length===0" description="暂无输入"></el-empty>
    <ul class="appraise-list">
      <li v-for="(item,index) in records" :key="index" class="item-container" @click="openResource(item)">
        <el-card :body-style="{ padding: '0px' }">
          <article class="item-parent">
            <div class="item-top">
              <img style="height: 100%;width: 100%;object-fit: contain" :src="getBackgroundStyle(item)" @error="imgError">
            </div>

            <div class="item-bottom">
              <h1 class="item-name single-line">{{ item.shareTitle }}</h1>
              <div style="display: flex;justify-content: space-between;margin-top: 15px">
                <span style="color: red;flex-shrink: 0">{{ item.sharePrice }}{{ item.shareUnit }}</span>
                <span class="single-line">{{ item.shareUser }}</span>
                <!-- <span>{{ item.sharePhone }}</span> -->
              </div>
              <p class="item-content second-line" style="height: 40px">{{ item.shareIntroduce }}</p>
            </div>
          </article>
        </el-card>
      </li>
    </ul>

    <div style="text-align: center;">
      <el-pagination class="pageAction" :current-page="currentPage" :page-size="12" :background="false" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
    </div>
  </article>

</template>

<script>
import backgroundImage from '../../../../../assets/images/resource/p0.jpg'
export default {
  props: {
    records: {
      type: Array,
      default() {
        return []
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
    }
  },
  methods: {

    getBackgroundStyle(item) {
      if (!item.logoUrl) {
        return backgroundImage
      }
      return item.logoUrl
    },
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    handleCurrentChange() {

    },
    openResource(item) {
      this.$emit('openResource', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.appraise {
  background-color: white;
  padding-right: 100px;
}
.appraise-list {
  display: flex;
  flex-wrap: wrap;
}
.item-container {
  width: 25%;
  box-sizing: border-box;
  padding: 20px 35px;

  .item-parent {
    cursor: pointer;
    transition: all 0.6s;
    .item-top {
      height: 195px;
      margin: 10px;
    }
    .item-bottom {
      width: 100%;
      background-color: rgb(242, 242, 242);
      padding: 20px;
      box-sizing: border-box;
      .item-name {
        font-weight: 400;
      }
      .item-content {
        margin-top: 10px;
        font-weight: 300;
        line-height: 25px;
      }
    }
  }
}

.pageAction {
  margin-top: 30px;
}
::v-deep .el-pagination {
  button {
    background: initial;
    .el-icon {
      font-size: 17px;
    }
  }
  .number {
    background: initial;
    font-size: 16px;
  }
}
</style>
